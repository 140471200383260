import React from 'react';
//import App from "./App";
import "./Panel.css";
import "./Chart.css";

import axios from 'axios';
import {XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries} from 'react-vis';

export default class MyChart extends React.Component {

    constructor(props) {
        console.log("constructor");
        super(props);
        this.state = {
            // line: this.props.line,
            // station: this.props.station,
            // begin: this.props.begin,
            // end: this.props.end,
            trains: []
        };
    }

    componentDidMount() {
        console.log("update");
        // axios.get(`http://localhost:8888/predict_train_fullness_range?temperature=25&date_from=${this.props.begin}&date_to=${this.props.end}&station=${this.props.station}&line=${this.props.line}`)
        //     .then(res => {
        //         const trains = Object.entries(res.data);
        //         let o = {};
        //         // console.log(trains);
        //         for (let d of trains){
        //             // console.log(d[0]);
        //             // console.log(d[1]);
        //             let dat = new Date(d[0]+'0');
        //             o[dat.getTime()+(2*3600*1000)] = d[1];
        //         }
        //         let arr = [];
        //         for (let e in o){
        //             let a = [];
        //             a[0] = e;
        //             a[1] = o[e];
        //             arr.push(a);
        //         }
        //         this.setState({ trains: arr });
        //         // console.log(this.state.trains);
        //         //this.setState({ trains: arr });
        //
        //
        //     })
        // const d1 = new Date('2020-10-06T18:00:00');
        // let date1 = d1.getTime();
        // const d2 = new Date('2020-10-06T19:00:00');
        // let date2 = d2.getTime();
        // const d3 = new Date('2020-10-06T20:00:00');
        // let date3 = d3.getTime();
        // let o = {};
        // o[date1] = 0.1555;
        // o[date2] = 0.18;
        // o[date3] = 0.05112;
        // const trains = o;
        // this.setState({ trains: Object.entries(trains) });

    }

    render(){
        console.log("render chart");
        const data = this.props.chart_data.map((d)=> {
            return {x: d[0],
                y: d[1]}
        });
        return (
            <div className="Panel"
                style={{
                    width: '650px',
                    height: '550px',
                    marginTop: '20px'
                }}
            >
                <h4 className="Panel-title">{this.props.title}</h4>
                <div className="Chart">
                    <XYPlot
                        width={600}
                        height={500}>
                        <HorizontalGridLines />
                        <LineSeries
                            data={data}/>
                        <XAxis tickFormat={function tickFormat(d){
                            const date = new Date(d+2*60*60*1000)
                            return date.toISOString().substr(11, 5)
                        }}/>
                        <YAxis />
                    </XYPlot>
                </div>

            </div>
        )
    }

}