import React, {Component} from 'react';

export default class MyButton extends Component {
    render() {
        return (
            <div className="Button">
                <h1>{this.props.heading}</h1>
                <button onClick={this.props.onClick}>{this.props.label}</button>
            </div>
        )
    }
}