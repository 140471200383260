import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import React, {Component} from "react";
import './MyDropDown.css';

const options = [
    'one', 'two', 'three'
];
const defaultOption = options[0];

class MyDropDown extends Component {

    _onSelect(){
        console.log("ARGH");
    }

    render() {
        return (
            <div className="MyDropDown"
            >
                <h4 className="Dropdown-title">{this.props.title}</h4>
                <Dropdown options={this.props.options} onChange={this.props.onSelect} value={this.props.defaultOption} placeholder="Select an option" />
            </div>
        )
    }
}



export default MyDropDown;