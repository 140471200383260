import React, {Component} from 'react';
import MyChart from './Chart';
import MyDropDown from "./DropDown";
//import FullnessPerTrainList from "./FullnessPerTrainList";
import MyButton from './Button';
import Panel from './Panel';
import './App.css';
import axios from "axios";

class App extends Component {

    constructor(props) {
        super(props);
        this.stations = [
            "Hamburg Sternschanze",
            "Hamburg Mittlerer Landweg",
            "Hamburg Allermöhe"
        ];
        this.station_to_lines = {
            "Hamburg Sternschanze": ['S11:Hamburg-Poppenbüttel', 'S21:Aumühle', 'S31:Hamburg-Neugraben'],
            "Hamburg Mittlerer Landweg": ['S21:Hamburg Elbgaustrasse'],
            "Hamburg Allermöhe": ['S21:Hamburg Elbgaustrasse']
        };
        this.days = [
            "heute", "morgen", "übermorgen"
        ];
        this.state = {
            station: "Hamburg Sternschanze",
            line: 'S11:Hamburg-Poppenbüttel',
            day: "heute",
            begin: "2020-10-06T18:00:00",
            end: "2020-10-06T20:00:00",
            trains: []
        };
        this.onSelectStation = this.onSelectStation.bind(this);
        this.onSelectLine = this.onSelectLine.bind(this);
        this.onSelectDay = this.onSelectDay.bind(this);
        this.onClickButton = this.onClickButton.bind(this);
    }

    onSelectStation(station){

        // window.alert("on select station");
        // window.alert(station);
        // console.log(station);
        this.setState({
            station: station.value
        })
    }

    onSelectLine(line){
        // window.alert("on select line");
        // window.alert(line);
        // console.log(line);
        this.setState({
            line: line.value
        })
    }

    onSelectDay(day){
        // window.alert("on select day");
        // window.alert(day);
        // console.log(day);
        this.setState({
            day: day.value
        })
    }

    dateToDateStringWithZeroHours(d){
        let month  = d.getMonth()+1;
        let day = d.getDate();
        month = month<10 ? '0'+month : month;
        day = day<10 ? '0'+day : day;
        let datestring = `${d.getFullYear()}-${month}-${day}T00:00:00`
        return datestring;
    }

    onClickButton(){
        console.log("on click prognostizierne");
        let begin_date = new Date();
        // console.log(begin_date);
        let end_date = new Date();
        switch(this.state.day){
            case "heute":
                break;
            case "morgen":
                begin_date = new Date(begin_date.getTime()+1*1000*60*60*24);
                break;
            case "übermorgen":
                begin_date = new Date(begin_date.getTime()+2*1000*60*60*24);
                break;
            default:
                break;
        }
        end_date = new Date(begin_date.getTime()+1*1000*60*60*24);
        let end_date_string = this.dateToDateStringWithZeroHours(end_date);
        let begin_date_string = this.dateToDateStringWithZeroHours(begin_date);
        // this.setState({
        //     begin : begin_date_string,
        //     end : end_date_string
        // })
        console.log(begin_date_string);
        console.log(end_date_string);
        let url = `https://kondor-dresden-receiver.kontacts2.de/predict_train_fullness_range?temperature=25&date_from=${begin_date_string}&date_to=${end_date_string}&station=${this.state.station}&line=${this.state.line}`;
        console.log(url);
        axios.get(url)
            .then(res => {
                const trains = Object.entries(res.data);
                let o = {};
                // console.log(trains);
                for (let d of trains){
                    // console.log(d[0]);
                    // console.log(d[1]);
                    let dat = new Date(d[0]+'0');
                    o[dat.getTime()+(2*3600*1000)] = d[1];
                }
                let arr = [];
                for (let e in o){
                    let a = [];
                    a[0] = e;
                    a[1] = o[e];
                    arr.push(a);
                }
                this.setState({ trains: arr });
                // console.log(this.state.trains);
                //this.setState({ trains: arr });


            })
        // const d1 = new Date('2020-10-06T18:00:00');
        // let date1 = d1.getTime();
        // const d2 = new Date('2020-10-06T19:00:00');
        // let date2 = d2.getTime();
        // const d3 = new Date('2020-10-06T20:00:00');
        // let date3 = d3.getTime();
        // let o = {};
        // o[date1] = 0.1555;
        // o[date2] = 0.18;
        // o[date3] = 0.05112;
        // const trains = o;
        // this.setState({ trains: Object.entries(trains) });
    }

    render(){
        return (
            <div className="App">
                <header className="App-header">
                    <h4>Prognosegraphen</h4>
                </header>
                <MyDropDown onSelect={this.onSelectStation} defaultOption={this.state.station} options={this.stations} title="Bahnhof"/>
                <MyDropDown onSelect={this.onSelectLine} defaultOption={this.station_to_lines[this.state.station][0]} options={this.station_to_lines[this.state.station]} title="Linie"/>
                <MyDropDown onSelect={this.onSelectDay} defaultOption={this.state.day} options={this.days} title="Tag"/>
                <MyButton onClick={this.onClickButton} label="Prognostizieren"/>
                <MyChart chart_data={this.state.trains} chart_type="time" title="Vorschau"/>
            </div>
        );
    }
}

export default App;
